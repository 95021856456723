import React from "react";
import { CancelIcon } from "../../Primitives";
import { useTableActionIconStyle } from "./table-action-icons.style";

export const CancelEditItemTableIcon: React.FC<{ isDisabled: boolean }> = ({ isDisabled }) => {
  const { small, greenFill, animatedBorder, disabled } = useTableActionIconStyle();
  const classNames = [small, animatedBorder];

  if (isDisabled) {
    classNames.push(disabled);
  } else {
    classNames.push(greenFill);
  }

  return (
    <div className={classNames.join(" ")}>
      <CancelIcon svgColor="white" style={{ flex: 1, padding: "2px" }} />
    </div>
  );
};
