import { Action, Options } from "@material-table/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { CancelEditItemTableIcon } from "components/Tables/table-action-icons/cancel-edit-item-table-icon.component";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { DeleteItemTableIcon } from "../../../../components/Tables/table-action-icons/delete-item-table-icon.component";
import { EditItemTableIcon } from "../../../../components/Tables/table-action-icons/edit-item-table-icon.component";
import { ShowHistoryTableIcon } from "../../../../components/Tables/table-action-icons/show-history-table-icon.component";
import { Colors, Shades } from "../../../../style/Colors";
import { usePomAnnouncementContext } from "../../../pages/announcement/pom-announcement.provider";
import { PomAnnouncementStatusModel } from "../../../repositories/models/announcements/pom-announcements.model";
import { IPomAnnouncementTableData, sortPomAnnouncementTableData } from "../pom-announcement-table-data";
import { AnnouncementTableMemo } from "./pom-announcement-table-memo.component";

interface IPomAnnouncementTable {
  className?: string;
}

export const PomAnnouncementTable: FunctionComponent<IPomAnnouncementTable> = (props) => {
  const { t } = useTranslation();

  const {
    curAnnouncementStatus,
    products,
    expandedProductIds,
    isLoading,
    changedProducts,
    onProductRemoved,
    onProductChanged,
    onProductChangeCancelled,
    onToggleHistory,
  } = usePomAnnouncementContext();

  const tableOptions: Options<any> = {
    toolbar: false,
    paging: false,
    actionsColumnIndex: -1,
    actionsCellStyle: {
      width: "1%",
      paddingRight: "15px",
    },
    rowStyle: (rowData) => {
      const product = rowData as IPomAnnouncementTableData;

      if (expandedProductIds.some((id) => id === product.id)) {
        return {
          backgroundColor: Shades.gray20,
          borderTop: `2px solid ${Colors.white}`,
        } as CSSProperties;
      }

      return {};
    },
  };

  const tableActions: ((rowData: object) => Action<object>)[] = [
    (rowData: object) => {
      const product = rowData as IPomAnnouncementTableData;
      return {
        hidden:
          curAnnouncementStatus === PomAnnouncementStatusModel.Published && products?.some((p) => p.id === product.id),
        icon: () => <DeleteItemTableIcon />,
        onClick: () => {
          onProductRemoved(product);
        },
      };
    },
    (rowData: object) => {
      const product = rowData as IPomAnnouncementTableData;
      return {
        hidden: curAnnouncementStatus === PomAnnouncementStatusModel.Draft || !product.history,
        icon: () => <ShowHistoryTableIcon isActive={expandedProductIds.some((p) => p === product.id)} />,
        onClick: () => {
          onToggleHistory(product);
        },
      };
    },
    (rowData: object) => {
      const product = rowData as IPomAnnouncementTableData;
      const isEditing = changedProducts?.some((p) => p.id === product.id) ?? false;
      return {
        hidden:
          curAnnouncementStatus === PomAnnouncementStatusModel.Draft || !products?.some((p) => p.id === product.id),
        icon: () => {
          return isEditing ? <CancelEditItemTableIcon isDisabled={false}/> : <EditItemTableIcon isDisabled={false}/>
        },
        onClick: () => {
          isEditing ? onProductChangeCancelled(product) : onProductChanged(product);
        },
      };
    },
  ];

  const getTableData = (): IPomAnnouncementTableData[] => {
    const filteredProducts = products?.filter((p) => !changedProducts?.some((cp) => cp.id === p.id));
    return [...(filteredProducts ?? []), ...(changedProducts ?? [])].sort(sortPomAnnouncementTableData);
  };

  return (
    <div className={props.className}>
      <AnnouncementTableMemo
        loading={isLoading}
        options={tableOptions}
        actions={tableActions}
        translate={t}
        data={getTableData()}
        expandedRows={expandedProductIds}
        changedProductIds={changedProducts?.map((p) => p.id) ?? []}
        emptyDataSourceMessage={t("pom.announcements.table.empty")}
      />
    </div>
  );
};
